.main_div_height {
  height: 100%;
  height: 100vh;
}

.img_height {
  width: 300px;
}

.border-page-division {
  border-right: 1px solid lightgray;
}

.sub_div_height {
  /* min-height: -moz-fill-available;
  min-height: -webkit-fill-available; */
  height: calc(100vh - 100px);
  /* overflow: scroll; */
}

.sub_div_height_full {
  min-height: 100vh;
  min-height: -moz-fill-available;
  min-height: -webkit-fill-available;
  overflow: scroll;
}

.xpa--addl-btm-pad {
  padding-bottom: 10rem;
}

svg.xpa-fill--red {
  fill: #ec5f7b;
}

svg.xpa-fill--green {
  fill: #1cc88a
}

svg.xpa-fill--orange {
  fill: #f6c23e;
}

.headerDropdown {
  border: none !important;
}

.static-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeec;
  position: static;
  bottom: 0;
  width: 100%;
  right: 0;
  left: 0;
  padding: 10px;
}

.fixed-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeec;
  position: fixed;
  bottom: 0;
  width: 100%;
  right: 0;
  left: 0;
  padding: 10px 3rem;
}

.fixed-bottom-logo {
  width: 150px;
}

#xpa-vi--otp {
  background: none !important;
  border: 1px solid black;
  font-size: 20px;
}

.xpa-vi--step-c {
  background: #fff3d5 !important
}

.xr--mock-video-wrap video {
  border: 2px solid #613c58;
  background: #613c58;
}

.xr--vi--setup-recorder {
  position: relative;
}

.xr--vi--setup--inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.xr--vi--loader {
  position: absolute;
  z-index: 10000;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #613c58;
  left: 20px;
}

.xpa--blink {
  animation: xpablink 3s linear infinite;
}

@keyframes xpablink {
  50% {
    opacity: 0.5;
  }
}

.xpa-vi--unlim--retakes {
  background: #d3f6f3 !important;
}

.xpa-vi--step-time {
  background: #fff3d5 !important;
}

.xpa-vi--prep-time {
  background: #d6f8b8 !important;
}

.xpa-vi--step-c {
  background: #ffc83d !important
}

.xpa-vi--retake-c {
  background: #d3f6f3 !important
}

.xpa-vi--chars-c {
  background: #fbdecc !important
}

.xpa-vi--relative .cds--header {
  position: relative;
}

.xvi-completion-page {
  min-height: 300px;
  min-height: 100vh;
  min-height: calc(100vh - 1px);
}

.vi-share-fullscreen {
  background: #fff;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.85;
  backdrop-filter: blur(4px);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vi-share-window {
  position: absolute;
  bottom: 110px;
  right: 50px;
  z-index: 10;
  opacity: 0.5;
}

.xpa-draftEditor-height .rdw-editor-main {
  height: 200px;
}

.xpa-draftEditor-height .DraftEditor-root {
  height: auto;
}

.xpa-m--auto {
  margin: auto;
}

.feedback-table .feedback-row {
  background-color: white;
  margin-top: 2rem !important;
}

.feedback-table .feedback-row:nth-of-type(even) {
  background-color: #68626250;
  padding-top: 1rem;
  padding-bottom: 1rem;
}