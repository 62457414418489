.registraton-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1055px) {
  .rf-wrap {
    min-width: 500px !important;
  }
}

.rf-wrap .cds--form-item {
  margin: 5px 10px;
}

.rf-btn {
  flex: 1;
  margin: 34px 10px 0px;
}

.css-web-height {
  height: 80vh;
  width: 100%;
  margin: 0px;
}

.left-bg-color {
  background-color: white;
}

.text-color-white {
  color: white !important;
  font-weight: bold;
}

.input-wid {
  min-width: 325px;
}

@media (min-width: 1055px) {
  .dropdown-margin {
    margin-top: -0.5rem;
  }
}