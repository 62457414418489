.sticky-top {
  position: sticky !important;
  top: 0;
  z-index: 9999999;
}

.arrow-fill-color {
  fill: white;
}

.subitems-card {
  background-color: #fff;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, .08);
  min-height: 9rem;
  left: 0;
  position: fixed;
  top: 0px;
  z-index: 1111;
  width: 100%;
  margin-top: 3rem !important;
  border-bottom: 2px solid #613c58;
}

.logout-height {
  height: 100%;
  width: 80px !important;
}

.selected-link {
  border-bottom: 2px solid white;
}

.css-maintenance-header {
  background-color: #6b6d72;
  color: white;
  width: 100%;
  height: 50px;
  position: sticky;
  z-index: 60000;
  top: 0px
}

/* .x-mobile-sidebar {
  position: absolute;
  width: 100%;
  width: 100vw;
  height: 100%;
  left: 0;
  right: 0;
  top: 3rem;
  bottom: 0;
  z-index: 9999;
} */


/* .cds--side-nav {
  z-index: 99999
} */

.cds--header,
.cds--header-panel {
  background-color: #162759 !important;
  border-bottom: 0px solid #613c58 !important;
  height: 60px;
}

.cds--header {
  overflow-x: scroll;
}

.cds--header::-webkit-scrollbar {
  display: none;
}


a.cds--header__name {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  padding: 0 2rem 0 1rem;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0.125rem solid transparent;
  -webkit-transition: border-color 110ms;
  transition: border-color 110ms;
  outline: none;
}
a.cds--header__name:focus {
  border-color: #fff;
}
.cds--header__name--prefix {
  font-weight: 400;
}
a.cds--header__name,
a.cds--header__name:hover {
  color: #f4f4f4;
}